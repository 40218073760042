import React, { useState } from 'react';

const Shipping = () => {


 return (
    <div>
      <h2>Shipping Information</h2>
   

    </div>
 );
};

export default Shipping;
