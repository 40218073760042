import UsersList from "./list/UsersList";

const Users = () => {
    return(
      <>
    <UsersList/>
    </>
    );
  };
  
export default Users;