import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../features/cartSlice";
import { Link } from "react-router-dom";

const Home = () => {
  const { items: data, status } = useSelector((state) => state.products);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    navigate("/cart");
  };

  const handleViewProduct = (product) => {
    navigate(`/product/${product._id}`);
  };

  return (
    <div className="home-container">
      <div className="hero-image">
        <img src="./images/Carlos.gif" alt="Hero Image" />
        <div className="hero-text">
          <h2>MISSION AND VALUES</h2>
          <div>
            <div>
              <span>
                <p>
                  At Gothrone, our mission is to inspire and empower individuals
                  through the fusion of fashion, technology, and sustainability.
                  We stand for pushing the limits of creativity, embracing
                  technological advancements, and championing environmental
                  responsibility. Join us in shaping a future where style is a
                  dynamic expression of self and a commitment to the planet.
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
      {status === "success" ? (
        <>
          <h2>New Arrivals</h2>
          <div className="hero-image">
            <div className="products">
              {data &&
                data?.map((product) => (
                  <div key={product._id} className="product-overlay">
                    <Link to={`/product/${product._id}`}>
                      <img
                        src={product.image.url}
                        alt={product.name}
                        className="product-image"
                      />
                    </Link>
                    <div className="product-details">
                      <h3>{product.name}</h3>
                      <div className="details">
                        <span>{product.desc}</span>
                        <span className="price">R{product.price}</span>
                      </div>
                      <div>
                      <button onClick={() => handleAddToCart(product)}>
                        {" "}
                        Add to Cart{" "}
                      </button>
                      <button onClick={() => handleViewProduct(product)}>
                        View
                      </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : status === "pending" ? (
        <p> Loading ... </p>
      ) : (
        <p>Unexpected error occured ... </p>
      )}
    </div>
  );
};

export default Home;
