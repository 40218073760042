import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <marquee behavior="" direction="">
          <div>
            <h2>Welcome to Scupture Africa</h2>
            <p>
              Sculpture Africa is a premier art company based in South Africa,
              dedicated to the creation and promotion of exquisite sculptures
              crafted from a variety of materials including metal, stone, and
              wood.
            </p>
          </div>
        </marquee>

        <div>
          <h2>Quick links</h2>
          <div className="footer-section-links">
            <ul>
              <li>
                <Link to="/contact">contact</Link>
              </li>
              <li>
                <Link to="/shop">shop</Link>
              </li>
              <li>
                <Link to="/forevent">events</Link>
              </li>
              <li>
                <Link to="/returns">returns and refunds</Link>
              </li>

              <li>
                <Link to="/terms">terms of service</Link>
              </li>

              <li>
                <Link to="/register">register new account</Link>
              </li>
            </ul>
          </div>
        </div>
        <marquee behavior="" direction="">
          <div>
            <h2>
              Join our newsletter now for free monthly sculpture magazines
            </h2>
            <p>
              and be the first to hear about newest drops and exclusive deals!
            </p>
          </div>
        </marquee>
      </div>
      <div className="Partnership">
        <h2>In Partnership with Sculpture Africa</h2>
      </div>
    </footer>
  );
};

export default Footer;
