import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../features/authSlice";
import { StyledForm } from "./StyledForm";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    streetNr: "",
    zipCode: "",
    country: "",
    phoneNumber: "",
    subscribe: false, // New field for subscription
    acceptTerms: false // New field for terms and conditions acceptance
  });

  useEffect(() => {
    if (auth._id) {
      navigate("/cart");
    }
  }, [auth._id, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the user has accepted the terms and conditions
    if (!user.acceptTerms) {
      alert("You must accept the terms and conditions to register.");
      return;
    }

    dispatch(registerUser(user));
  };

  return (
    <div className="register-container">
    <StyledForm onSubmit={handleSubmit}>
      <h2>Register</h2>
      <h3>First name</h3>
      <input
        type="text"
        placeholder="First name"
        onChange={(e) => setUser({ ...user, name: e.target.value })}
      />
      <h3>Last Name</h3>
      <input
        type="text"
        placeholder="Last name"
        onChange={(e) => setUser({ ...user, lastName: e.target.value })}
      />
      <h3>Email</h3>
      <input
        type="email"
        placeholder="email"
        onChange={(e) => setUser({ ...user, email: e.target.value })}
      />
      <h3>Password</h3>
      <input
        type="password"
        placeholder="Password"
        onChange={(e) => setUser({ ...user, password: e.target.value })}
      />
      <h3>Street Number</h3>
      <input
        type="text"
        placeholder="Street Number"
        onChange={(e) => setUser({ ...user, streetNr: e.target.value })}
      />
      <h3>Zip Code</h3>
      <input
        type="text"
        placeholder="Zip Code"
        onChange={(e) => setUser({ ...user, zipCode: e.target.value })}
      />
      <h3>Country</h3>
      <input
        type="text"
        placeholder="Country"
        onChange={(e) => setUser({ ...user, country: e.target.value })}
      />
      <h3>Phone Number</h3>
      <input
        type="text"
        placeholder="Phone Number"
        onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })}
      />
      <h3>Subscribe to Newsletter</h3>
      <input
        type="checkbox"
        onChange={(e) => setUser({ ...user, subscribe: e.target.checked })}
      />
      <>
        <h3>Accept Terms and Conditions</h3>
        <li>
          <Link to="/terms">terms of service</Link>
        </li>
        <input
          type="checkbox"
          onChange={(e) => setUser({ ...user, acceptTerms: e.target.checked })}
        />
      </>
      <button>
        {auth.registerStatus === "pending" ? "Submitting..." : "Register"}
      </button>
      {auth.registerStatus === "rejected" ? <p>{auth.registerError}</p> : null}
    </StyledForm>
    </div>
  );
};

export default Register;
