import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../features/cartSlice";
import { Link } from "react-router-dom";


const Shop = () => {
  const { items: data, status } = useSelector((state) => state.products);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    navigate("/cart");
  };


  return (
    <div className="home-container">
      {status === "success" ? (
        <>
          <h2>New Arrivals</h2>
          <div className="hero-image">
            <div className="products">
              {data &&
                data?.map((product) => (
                  <div key={product._id} className="product-overlay">
                    <Link to={`/product/${product._id}`}>
                      <img
                        src={product.image.url}
                        alt={product.name}
                        className="product-image"
                      />
                    </Link>
                    <div className="product-details">
                      <h3>{product.name}</h3>
                      <div className="details">
                        <span>{product.desc}</span>
                        <span className="price">R{product.price}</span>
                      </div>
                      <button onClick={() => handleAddToCart(product)}>
                        {" "}
                        Add to Cart{" "}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : status === "pending" ? (
        <p> Loading ... </p>
      ) : (
        <p>Unexpected error occured ... </p>
      )}
    </div>
  );
};

export default Shop;
