// Contact.js
import React, { useState } from "react";


const backgroundImageUrl = `url(/images/Carlos_0760.jpg)`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add your logic for handling the form submission here

    console.log("Form submitted:", formData);
    // Reset the form after submission
    setFormData({
      name: "",
      email: "",
      message: ""
    });
  };

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    minHeight: "100vh" // Make sure the content covers the full viewport height
  };

  return (
    <div className="contact-container" style={backgroundStyle}>
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit} className="form-container">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>

        <button type="submit">Submit</button>
      </form>
  

      <div className="reach-out-container">
        <h2>Reach Out</h2>
        <p>Contact us:</p>
        <p>Instagram: gothrone01</p>
        <p>Email: support@gothrone.xyz</p>
      </div>
    </div>
  );
};

export default Contact;
